
.csdlc-child,
.csdlc3,
.icon {
  position: absolute;
}
.icon {
  height: 56.21%;
  width: 11.96%;
  top: 22.22%;
  right: 82.89%;
  bottom: 21.57%;
  left: 5.15%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.csdlc3 {
  top: 0;
  left: calc(38% - 41px);
  line-height: 36px;
}
.csdlc2,
.vector-icon4 {
  position: absolute;
  top: 108px;
  left: 0;
  width: 236px;
  height: 36px;
}
.vector-icon4 {
  height: 44.6%;
  width: 10.09%;
  top: 27.78%;
  right: 83.67%;
  bottom: 27.62%;
  left: 6.24%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.biosdlc3,
.vector-icon5 {
  position: absolute;
  top: 72px;
  left: 0;
  width: 236px;
  height: 36px;
}
.vector-icon5 {
  height: 46.03%;
  width: 11.96%;
  top: 27.78%;
  right: 82.89%;
  bottom: 19.44%;
  left: 5.15%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.bi-migrator7 {
  position: absolute;
  top: 36px;
  left: 0;
  width: 236px;
  height: 36px;
}
.datacloud-child1 {
  position: absolute;
  height: 61.11%;
  width: 11.96%;
  top: 19.44%;
  right: 82.89%;
  bottom: 19.44%;
  left: 5.15%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.datacloud3,
.csdlc-child,
.telepresence-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 236px;
  height: 36px;
}
.csdlc-child,
.telepresence-child {
  background-color: var(--bk6);
  border-bottom: 1px solid var(--color-lightcyan);
  box-sizing: border-box;
}
.vector-icon6 {
  position: absolute;
  height: 47.22%;
  width: 9.24%;
  top: 25%;
  right: 83.52%;
  bottom: 27.78%;
  left: 7.24%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.telepresence1 {
  position: absolute;
  top: 180px;
  left: 0;
  width: 236px;
  height: 36px;
}
.cloud-photo-camera-icon {
  position: absolute;
  height: 47.91%;
  width: 11.96%;
  top: 25%;
  right: 82.98%;
  bottom: 27.09%;
  left: 5.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.imagecloud1 {
  position: absolute;
  top: 144px;
  left: 0;
  width: 236px;
  height: 36px;
}
.edc-icon1 {
  position: absolute;
  height: 47.91%;
  width: 11.96%;
  top: 25%;
  right: 82.98%;
  bottom: 27.09%;
  left: 6.27%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.edc1 {
  position: absolute;
  top: 216px;
  left: 0;
  width: 236px;
  height: 36px;
}
.csdlc2,
.biosdlc3,
.bi-migrator7,
.datacloud3,
.telepresence1,
.imagecloud1,
.edc1 {
  transition: background-color 2s ease, border-bottom 2s ease, color 2s ease;
}
.dropdown-products {
  /* display: none;  */
  /* position: absolute; Position relative to the 'products' div */
  position: fixed;
  background-color: var(--bk6); 
  box-shadow: 0 2px 2px rgba(63, 129, 173, 0.25); 
  width: 236px; 
  height: 252px;
  margin-top: 30px;
  z-index: 1000; /* High z-index to ensure it's on top of other content */
  text-align: left;
  font-size: var(--font-size-base); 
  color: var(--b3); 
  font-family: var(--font-proxima-nova); 
  border: 1px solid var(--color-lightcyan); 
  border-top: none; /* No top border to blend with the toggle button */
  display: block; /* Make sure it's block or inline-block, not 'none' */
  opacity: 0; /* Start fully transparent */
  visibility: hidden; /* By default, it shouldn't be visible */
  transform: translateY(-20px); /* Start a bit higher for a slide effect */
  transition: opacity 5s ease, transform 5s ease, visibility 5s;
  font-weight: 200;
}

.show-dropdown-products {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Now it's visible */
  transform: translateY(0); /* Align it to its natural position */
}
/* Common styles for hover state */
/* Hover effects for larger screens */
.csdlc2:hover,
.biosdlc3:hover,
.bi-migrator7:hover,
.datacloud3:hover,
.telepresence1:hover,
.imagecloud1:hover,
.edc1:hover {
  background-color: #1674b4;
  border-bottom: 1px solid #002169;
}

.csdlc2:hover .csdlc3,
.biosdlc3:hover .csdlc3,
.bi-migrator7:hover .csdlc3,
.datacloud3:hover .csdlc3,
.telepresence1:hover .csdlc3,
.imagecloud1:hover .csdlc3,
.edc1:hover .csdlc3 {
  color: #fff;
}

/* Disable hover effects for mobile devices */
@media (max-width: 450px) {
  .csdlc2,
  .biosdlc3,
  .bi-migrator7,
  .datacloud3,
  .telepresence1,
  .imagecloud1,
  .edc1 {
    /* Remove hover styles */
    background-color: #d2edff;
    border-bottom: none !important;
    width: 100vw;
  }

  .csdlc2 .csdlc3,
  .biosdlc3 .csdlc3,
  .bi-migrator7 .csdlc3,
  .datacloud3 .csdlc3,
  .telepresence1 .csdlc3,
  .imagecloud1 .csdlc3,
  .edc1 .csdlc3 {
    color: #1674b4;
  }
  .csdlc3{
    left: 85px;
    color: #00558F;
  }
}
