/* header styles here  */

/* .header-child {
    height: 92px;
    width: 1280px;
    position: relative;
    background-color: var(--color-lavender);
    box-shadow: 0 2px 2px rgba(63, 129, 173, 0.25);
    display: none;
    max-width: 100%;
  } */
  .LineDiv{
    display: none;
  }
  .LineDiv1 {
    display: block;
    width: 90vw;
    position: relative;
    border-top: 1px solid #dfdfdf;
    box-sizing: border-box;
    height: 2px;
    margin-top: 15px;
  }  
  .incubated-at,
  .logo-icon {
    position: relative;
    z-index: 1;
  }
  .logo-icon {
    height: 100%;
    width: auto;
  }
  .incubated-at {
    white-space: nowrap;
  }
  .iimb-logo-1 {
    align-self: stretch;
    height: 100%;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 1;
    cursor: pointer;
  }
  .logo-parent,
  .text {
    display: flex;
    justify-content: flex-start;
  }
  .text {
    height: 100%;
    width: 126px;
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px 0;
  }
  .logo-parent {
    /* min-width: 263px; */
    min-width: 17.7512vw;
    flex-direction: row;
    align-items: center;
    gap: 0 33px;
  }
  .header-menu{
    display: none;
  }
  .about,
  .home {
    position: relative;
    text-transform: uppercase;
    /* font-weight: 501; */
    z-index: 1;
  }
  .home:hover{
    cursor: pointer;
  }
  .about:hover {
    cursor: pointer;
  }
  .cta-header-child {
    height: 30px;
    width: 431px;
    position: relative;
    border-radius: var(--br-11xl);
    background-color: var(--b6);
    display: none;
    max-width: 100%;
  }
  .frame,
  .line-icon {
    position: relative;
    z-index: 1;
  }
  .line-icon {
    height: 15px;
    width: 15px;
  }
  .frame {
    text-transform: lowercase;
    /* font-weight: 501; */
    white-space: nowrap;
  }
  .layerx {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0 6px;
  }
  .vector-icon {
    height: 15px;
    width: 22.7px;
    position: relative;
    z-index: 1;
  }
  .contactvaidhyameghacom {
    position: relative;
    text-transform: lowercase;
    /* font-weight: 501; */
    white-space: nowrap;
    z-index: 1;
  }
  .cta-header,
  .layerx1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .layerx1 {
    align-items: center;
    gap: 0 7px;
  }
  .cta-header {
    border-radius: var(--br-11xl);
    background-color: var(--b6);
    align-items: flex-start;
    padding: var(--padding-7xs) var(--padding-sm) 5px;
    box-sizing: border-box;
    gap: var(--gap-11xl);
    max-width: 100%;
    z-index: 1;
  }
  .services1 {
    position: relative;
    text-transform: uppercase;
    /* font-weight: 501; */
  }
  .service-arrow-icon {
    width: 10px;
    height: 10.5px;
    position: relative;
  }
  .services,
  .subscribenow {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .subscribenow {
    height: 14px;
    flex-direction: column;
    padding: var(--padding-10xs) 0 0;
    box-sizing: border-box;
    font-weight: 501;
  }
  .services {
    flex-direction: row;
    gap: 2px;
    z-index: 1;  
  }
  .services:hover {
    cursor: pointer;
  }
  .products1 {
    position: relative;
    text-transform: uppercase;
    /* font-weight: 501; */
  }
  .products-arrow-icon {
    width: 10px;
    height: 10.5px;
    position: relative;
  }
  .products,
  .products-arrow-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .products-arrow-wrapper {
    height: 14px;
    flex-direction: column;
    padding: var(--padding-10xs) 0 0;
    box-sizing: border-box;
  }
  .products {
    /* flex: 1; */
    flex-direction: row;
    gap: 3px;
    z-index: 1;
  }
  .products:hover {
    cursor: pointer;
  }
  .research {
    position: relative;
    text-transform: uppercase;
    /* font-weight: 501; */
    z-index: 1;
  }
  .text1 {
    flex: 0.8617;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-smi) 0 0;
  }
  .blog {
    position: relative;
    text-transform: uppercase;
    /* font-weight: 501; */
    z-index: 1;
  }
  .blog:hover {
    cursor: pointer;
  }
  .text2 {
    display: none;
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-xs) 0 0;
  }
  .contact {
    position: relative;
    text-transform: uppercase;
    /* font-weight: 501; */
    cursor: pointer;
    z-index: 1;
  }
  .contact:hover {
    cursor: pointer;
  }
  .cta-header-parent,
  .rectangle {
    display: flex;
    justify-content: flex-start;
  }
  .rectangle {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 0 20px;
    color: var(--b1);
  }
  .cta-header-parent {
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    gap: 17px 0;
    max-width: 100%;
    /* min-width: 643px; */
    min-width: 39vw;
    color: var(--b2);
  }
  .header,
  .header-inner,
  .home-parent {
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
  }
  .home-parent {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0 var(--padding-12xs) 0 0;
    gap: 0 32px;
  }
  .header,
  .header-inner {
    align-items: flex-start;
  }
  .header-inner {
    /* min-width: 643px; */
    min-width: 39vw;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0 0;
    font-size: var(--font-size-base);
    color: var(--b1);
    font-weight: 501;
    font-family: 'Proxima Nova', sans-serif;
  }
  .header {
    width: 100vw;
    align-self: stretch;
    background-color: var(--color-lavender);
    box-shadow: 0 2px 2px rgba(63, 129, 173, 0.25);
    flex-direction: row;
    justify-content: space-between;
    padding: var(--padding-smi) 60px var(--padding-4xs);
    top: 0;
    z-index: 99;
    position: sticky;
    /* gap: var(--gap-xl); */
    /* column-gap: 27.48rem; */
    column-gap: 29vw;
    text-align: left;
    font-size: 12px;
    color: #696969;
    font-family: 'Proxima Nova', sans-serif;
    /* overflow-x: auto; */
    display: flex;
    flex-wrap: nowrap;
  }
  .header-inner > div {
    flex: 0 0 auto;
  }

/* newsletter and footer styles here  */

.newsletter-child {
    height: 395px;
    width: 1280px;
    position: relative;
    background-color: var(--b4);
    display: none;
    max-width: 100%;
  }
  .subscribe-c-t-a-child {
    height: 48px;
    width: 269px;
    position: relative;
    border-radius: var(--br-21xl);
    background-color: var(--color-deepskyblue);
    display: none;
  }
  .subscribe-to-our {
    flex: 1;
    position: relative;
    font-size: var(--font-size-lg);
    line-height: 12px;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--bk5);
    text-align: center;
    z-index: 2;
  }
  .subscribe-c-t-a {
    /* cursor: pointer; */
    border: 0;
    padding: var(--padding-lg) var(--padding-mini) var(--padding-lg)
      var(--padding-sm);
    background-color: var(--color-deepskyblue);
    width: 269px;
    border-radius: var(--br-21xl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    white-space: nowrap;
    z-index: 1;
  }
  /* .subscribe-c-t-a:hover {
    background-color: #3b91cc;
  } */
  .stay-connected-with {
    font-weight: 300;
  }
  .vaidhyamegha2 {
    font-weight: 500;
  }
  .stay-connected-with-container {
    margin: 0;
    width: 407px;
    position: relative;
    font-size: inherit;
    display: inline-block;
    max-width: 100%;
    z-index: 1;
    font-family: inherit;
  }
  .subscribe-now-button-child {
    height: 86px;
    width: 634px;
    position: relative;
    border-radius: var(--br-41xl);
    background-color: var(--bk6);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
    display: none;
    max-width: 100%;
  }
  .email-input {
    position: relative;
    z-index: 2;
  }
  .email-input {
    height: 42px; /* Adjust height as needed */
    width: 300px; /* Take full width of the container */
    border: none; /* Remove border */
    border-radius: 20px; /* Rounded corners as seen in the image */
    padding-left: 5%; /* Padding inside the input to not touch the borders */
    margin-right: 10px; /* Space between the input and the button */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Slight shadow inside */
    outline: none; /* Remove the outline to avoid the blue border when clicked */
    font-size: 16px; /* Font size similar to the placeholder */
  }
  
  .email-input::placeholder {
    color: #A9A9A9; /* Placeholder color */
    font-size: 16px; /* Placeholder font size */
  }
  .location-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0 0;
  }
  .subscribe-now-child {
    height: 50px;
    width: 243px;
    position: relative;
    border-radius: var(--br-31xl);
    background-color: var(--g5);
    border-right: 2px solid var(--g6);
    border-bottom: 2px solid var(--g6);
    box-sizing: border-box;
    display: none;
  }
  .subscribe-now1 {
    position: relative;
    font-size: 30px;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--bk6);
    text-align: left;
    z-index: 1;
    transition: color 2s ease;
  }
  .subscribe-now,
  .subscribe-now-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
  }
  .subscribe-now {
    cursor: pointer;
    border: 0;
    padding: var(--padding-6xs) var(--padding-7xs) var(--padding-7xs);
    background-color: var(--g5);
    width: 243px;
    border-radius: var(--br-31xl);
    border-right: 2px solid var(--g6);
    border-bottom: 2px solid var(--g6);
    justify-content: center;
    white-space: nowrap;
    z-index: 2;
    transition: background-color 2s ease, border-right 2s ease, border-bottom 2s ease; 
  }
  .subscribe-now:hover {
    background-color: #16e387;
    border-right: 2px solid #343a84;
    border-bottom: 2px solid #343a84;
  }
  .subscribe-now:hover .subscribe-now1 {
    color: #002169;
  }
  .subscribe-now-button {
    align-self: stretch;
    border-radius: var(--br-41xl);
    background-color: var(--bk6);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
    justify-content: space-between;
    padding: var(--padding-lg) var(--padding-xl) var(--padding-lg)
      var(--padding-12xl);
    max-width: 100%;
    gap: var(--gap-xl);
    z-index: 1;
    font-size: var(--font-size-xl);
    color: var(--bk3);
  }
  .newsletter1,
  .subscribe-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .subscribe-text {
    align-self: stretch;
    gap: 17px 0;
  }
  .newsletter1 {
    width: 49.285%;
    padding: var(--padding-4xs) 0 0;
    box-sizing: border-box;
  
  }
  .newsletter-1-icon {
    width: 376px;
    position: relative;
    margin-left: -20px;
    max-height: 100%;
    object-fit: cover;
    max-width: 100%;
    z-index: 1;
  }
  .newsletter {
    align-self: stretch;
    background-color: #93CFF7;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: var(--padding-36xl) var(--padding-71xl) 51px;
    box-sizing: border-box;
    max-width: 100%;
    gap: var(--gap-xl);
    z-index: 2;
  }
  .footer-child {
    align-self: stretch;
    height: 335px;
    position: relative;
    background-color: var(--bk6);
    display: none;
  }
  .footer-logo-icon1 {
    height: 84px;
    width: 141px;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  .contact-us-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 0 var(--padding-11xl) 0 var(--padding-base); */
  }
  .icon-fb {
    height: 100%;
    width: 100%;
    min-height: 30px;
    z-index: 1;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1.667);
  }
  .wrapper-icon-fb {
    height: 30px;
    width: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-x,
  .wrapper-icon-fb,
  .wrapper-icon-x {
    min-height: 30px;
    z-index: 1;
  }
  .icon-x {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1.667);
  }
  .wrapper-icon-x {
    height: 30px;
    width: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-linkedin {
    height: 100%;
    width: 100%;
    min-height: 30px;
    z-index: 1;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1.667);
  }
  .wrapper-icon-linkedin {
    height: 30px;
    width: 30px;
    position: relative;
    min-height: 30px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-youtube {
    height: 100%;
    width: 100%;
    min-height: 30px;
    z-index: 1;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1.667);
  }
  .wrapper-icon-youtube {
    height: 30px;
    width: 30px;
    position: relative;
    min-height: 30px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-insta {
    height: 100%;
    width: 100%;
    min-height: 30px;
    z-index: 1;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1.667);
  }
  .wrapper-icon-insta {
    height: 30px;
    width: 30px;
    position: relative;
    min-height: 30px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* General styles for wrapper-icon containers to include transition effects */
  .wrapper-icon-fb,
  .wrapper-icon-x,
  .wrapper-icon-linkedin,
  .wrapper-icon-youtube,
  .wrapper-icon-insta {
    border-radius: 50%; /* Circular background on hover, remove if not desired */
    transition: background-color 2s ease; /* Smooth transition for background color */
  }
  /* Hover state changes for each social media icon wrapper */
  .wrapper-icon-fb:hover,
  .wrapper-icon-x:hover,
  .wrapper-icon-linkedin:hover,
  .wrapper-icon-youtube:hover,
  .wrapper-icon-insta:hover {
    background-color: #4299D4; /* Background color on hover */
    cursor: pointer;
  }
  /* Ensuring the icon images also transition smoothly */
  .icon-fb,
  .icon-x,
  .icon-linkedin,
  .icon-youtube,
  .icon-insta {
    transition: transform 2s ease; /* Smooth transition for any transformations */
  }
  /* Example transform on hover, such as a slight scale */
  .wrapper-icon-fb:hover .icon-fb,
  .wrapper-icon-x:hover .icon-x,
  .wrapper-icon-linkedin:hover .icon-linkedin,
  .wrapper-icon-youtube:hover .icon-youtube,
  .wrapper-icon-insta:hover .icon-insta {
    transform: scale(1.1); /* Slightly enlarges the icon on hover */
  }
  .social-media-icons1,
  .social-media-icons1-mobile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 10px;
  }
  .logo-with-text,
  .social-icons-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .logo-with-text {
    align-items: center;
    gap: 22px 0;
  }
  .social-icons-row {
    align-items: flex-start;
    padding: var(--padding-6xs) 0 0;
  }
  .products6 {
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    z-index: 1;
  }
  .bi-migrator6,
  .biosdlc2,
  .csdlc1,
  .datacloud2, 
  .imagecloud,
  .telepresence,
  .edc1 {
    margin: 0;
    cursor: pointer;
  }
  .hover-item:hover{
    color: #002169;
  }
  .footer-link.hover-item2:hover{
    color: #023095;
  }
  .datacloud-bi-migrator-biosdlc {
    position: relative;
    font-size: var(--font-size-base);
    line-height: 24px;
    color: var(--b3);
    z-index: 1;
  }
  .linked-in-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px 0;
    line-height: 22px;
  }
  .services3 {
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    z-index: 1;
  }
  .bio-informatics1,
  .clinical-trials2,
  .digital-health2,
  .imaging2 {
    margin: 0;
    cursor: pointer;
  }
  .bio-informatics-clinical-trial-container {
    position: relative;
    font-size: var(--font-size-base);
    line-height: 24px;
    color: var(--b3);
    z-index: 1;
  }
  .newsletter-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px 0;
    line-height: 22px;
  }
  .reach-us {
    height: 24px;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    z-index: 1;
  }
  .home-category-brand {
    position: relative;
    top: 0;
    left: 1px;
    width: 15px;
    height: 15px;
    z-index: 1;
  }
  .phoneno,
  .mailtovaid {
    gap: 7px;
    display: flex;
    align-items: center;
  }
  .create-a-chatbot-gpt-using-pyt {
    position: relative;
    left: 0;
    width: 18px;
    height: 12px;
    z-index: 1;
  }
  .hyderabad-location-text {
    width: 18px;
    height: 38px;
    position: relative;
  }
  .contact-vaidhya-megha-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs) 0 0;
  }
  .bio-s-d,
  .contactvaidhyameghacom1 {
    position: relative;
    text-transform: lowercase;
    font-weight: 500;
    white-space: nowrap;
    z-index: 1;
    cursor: pointer;
  }
  .phoneno ,
  .mailtovaid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
  }
  .products-text,
  .vector-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px 0;
  }
  .vector-frame {
    flex-direction: row;
    gap: 0 7px;
  }
  .location-icon {
    width: 14.9px;
    height: 19px;
    position: relative;
    z-index: 1;
  }
  .footer-location {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-10xs) 0 0;
  }
  .attapur-hyderguda-hyderabad,
  .telangana-500048 {
    margin: 0;
  }
  .attapur-hyderguda-hyderabad-container {
    position: relative;
    font-weight: 500;
    z-index: 1;
  }
  .reach-us-section {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 10px;
    line-height: 1.4;
  }
  .contact-info-section {
    flex: 1;
    flex-direction: column;
    padding: 0 var(--padding-12xs) 0 0;
    gap: 7px 0;
  }
  .contact-info-section,
  .location-input,
  .subscribe-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .location-input {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 var(--padding-11xs);
    font-size: var(--font-size-base);
    color: var(--b3);
  }
  .subscribe-button {
    /* width: 257px; */
    /* width: 267px; */
    flex-direction: column;
    gap: 6px 0;
    line-height: 20px;
  }
  .line-framecopyright,
  .social-media-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  .social-media-icons {
    width: 96.561%;
    /* width: 1151px; */
    justify-content: space-between;
    padding: 0 var(--padding-12xs) 0 0;
    gap: var(--gap-xl);
  }
  .social-media-icons1-mobile,
  .followus-mobile {
    display: none;
  }
  .iso-logo-row{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .iso-logo-container{
    /* margin-right: 30%; */
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 150px;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .iso-logo-cert{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .certifications-text, .partners-text{
    color: #9A9A9A;
    font-size: 16px;
    font-family: var(--font-proxima-nova);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .partners-vm{
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* justify-content: space-between; */
  }
  .partners-vm-div{
    box-sizing: border-box;
    padding: 0 30px;
    border-left: 2px solid #D9D9D9;
  }
  .aws-partner-div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .vertical-line{
    color: #B1B1B1;
  }
  .footer-logo{
    width: 90px;
    height: 90px;
  }
  .startupindia{
    position: relative;
    top: 10px;
    cursor: pointer;
    height: 45px;
    width: 215px;
  }
  .line-framecopyright {
    width: 84.02%;
    /* width: 1232px; */
    justify-content: flex-start;
    padding: 0 var(--padding-xl);
  }
  .copyright-2018-24,
  .image-icon {
    position: relative;
    max-width: 100%;
    z-index: 1;
  }
  .image-icon {
    align-self: stretch;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
    margin-top: -1px;
  }
  .copyright-2018-24 {
    width: 50%;
    /* padding-left: 11%; */
    font-size: var(--font-size-base);
    font-weight: 500;
    color: var(--g5);
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  .privacy-policy11,
  .terms-and-conditions1,
  .refund-and-cancellation1{
    cursor: pointer;
  }
  .privacy-div{
    color: #1674b4;
    position: relative;
    width: 50%;
    display: flex;
    font-size: var(--font-size-base);
    font-weight: 500;
    flex: 1;
    justify-content: center;
  }
  .straight-line {
    color: #c7c7c7;
    margin-left: 8px;
    margin-right: 8px;
  }
  .copyright-div{
    /* gap: 20px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  .footer-link, .header-link {
    text-decoration: none;
    color: inherit;
  }
  
  .footer-link{
    color: inherit;
  }
  
  .footer1 {
    align-self: stretch;
    align-items: center;
    padding: var(--padding-26xl) 0 34px;
    box-sizing: border-box;
    gap: 10px;
    max-width: 100%;
    margin-top: -1px;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--g6);
    font-family: 'Proxima Nova', sans-serif;
  }
  .footer1,
  .frame-unlockinnovation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .frame-unlockinnovation {
    align-self: stretch;
    align-items: flex-start;
    max-width: 100%;
    text-align: left;
    font-size: var(--font-size-17xl);
    color: var(--bk6);
    font-family: var(--font-proxima-nova);
  }
  @media screen and (min-width: 320px) and (max-width: 568px) {
    .math-final-logo {
        width: 75px;
        max-width: 100%;
        height: auto;
    }
  }
  @media screen and (min-width: 360px) and (max-width: 915px) {
    .math-final-logo {
        width: 95px; 
        max-width: 100%; 
        height: auto;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1280px) {
    .math-final-logo {
      width: 120px;
      margin-left: 20px;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 1280px) {
    .math-final-logo {
      width: 120px;
      margin: 20px auto;
      padding: 15px;
    }
  }
  @media screen and (min-width: 1440px) {
    .math-final-logo {
      width: 150px;
    }
    /* .iso-logo-cert {
      margin-left: 12%;
    } */
  }
  @media  screen and (max-width: 1200px) and (min-width: 750px){
    .header{
      column-gap: normal;
    }
    
  }
  @media  screen and (max-width: 1051px) and (min-width: 750px){
    .header{
      padding: 10px 20px;
    }
    .logo-parent{
      width: 250px;
    }
    .header-inner{
      font-size: 15px;
    }
    .copyright-div{
      flex-direction: column-reverse;
      gap: 20px;
    }
    .privacy-div,.copyright-2018-24{
      text-align: center;
      width: 520px;
    }
    .line-framecopyright{
      padding: 0 20px;
      width: 100%;
    }
    
  }
  @media  screen and (max-width: 750px) {
    
  }

  @media screen and (max-width: 450px) {
    .header-inner {
      display: none;
    }
    .header-menu {
      display: flex;
      align-items: center;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      top: 10px;
      left: 10px;
      height: auto;
      width: auto;
    }
    .menu-icon {
      width: 36px;
      height: 30px;
      position: relative;
    }
    
    .dropdown-menu {
      position: fixed;
      top: 75px; 
      left: 0;
      right: 0;
      width: 100vw;
      background-color: #38c7ff;
      border-top: 1px solid #d2edff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      /* padding: 10px 0; */
      text-align: center;
    }
    
    .menu-item {
      padding: 10px 20px;
      border-bottom: 1px solid #ddd;
      position: relative;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      font-weight: 500;
      font-family: 'Proxima Nova';
      color: #002169;
      text-align: left;
      z-index: 1000;
      display: flex;
      gap: 10px;
    }
    
    .menu-item:last-child {
      border-bottom: none;
    }
    .dropdown-services, .dropdown-products {
      position: relative;
      display: block;
      overflow-y: auto;
      width: 100vw;
      max-height: 300px;
    }
    .show-dropdown-services, .show-dropdown-products {
      visibility: visible;
      margin-top: 0;
    }
    .product-icon-m,
    .bio-informatics-service-icon2, .clinical-trials-service-icon2,
    .digital-health-service-icon2, .imaging-service-icon2 {
      position: relative; 
      height: 22px;
      width: 22px;
      left: 40px;
    }
    .imaging5 {
      position: relative;
      left: 40px;
      /* font-weight: 600; */
      color: #00558F;
    }
    .csdlc2-m, .biosdlc3-m, .bi-migrator7-m, .datacloud3-m, .telepresence1-m, .edc1-m, .imagecloud1-m,
    .imaging-in-service2, .digital-health-in-service2,
    .clinical-trials-in-service2, .bioinformatics-in-service2 {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      height: 40px;
      background-color: #d2edff;
      gap: 20px;
      text-align: left;
      font-size: 17px;
      color: #1674b4;
      font-family: 'Proxima Nova';
      align-items: center;
    }
    .header-child {
      display: block;
    }
    .mobile-blog-page{
      display: none;
    }
    .logo-parent {
      min-width: 214px;
      position: relative;
      left: 0;
      height: auto;
      display: flex;
      justify-content: center;
    }
    .iimb-logo-1 {
      width: auto; 
      max-width: 100%; 
      height: auto; 
    }
    .cta-header-parent {
      min-width: 643px;
    }
    .header {
      /* height: 90px; */
      padding-left: 8vw;
      width: 100%;
      gap: 16vw;
      /* overflow-x: auto; */
    }
    .stay-connected-with-container {
      font-size: var(--font-size-3xl);
      text-align: center;
    }
    .email-address1 {
      font-size: var(--font-size-base);
    }
    .subscribe-text {
      align-items: center;
    }
    .newsletter1 {
      width: 100%;
    }
    .newsletter {
      padding: var(--padding-17xl) var(--padding-xl) var(--padding-14xl);
      box-sizing: border-box;
      flex-direction: column;
    }
    .linked-in-icon {
      align-items: flex-start;
      order: 2;
    }
    .datacloud-bi-migrator-biosdlc {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
    }
    .newsletter-frame {
     align-items: flex-start; 
     order: 3;
    }
    .bio-informatics-clinical-trial-container {
      text-align: left;
    }
    /* .hs-form-51f8b305-ab19-4629-b2fc-953e658b4135_942a9d74-81a2-4d14-8672-4c48ace5ed02.hs-form {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    div#hbspt-form-942a9d74-81a2-4d14-8672-4c48ace5ed02 {
      align-items: center;
      display: flex;
      justify-content: center;
    } */
    .subscribe-button {
      align-items: flex-start;
      order: 4;
    }
    .location-input{
      align-items: flex-start;
    }
    .contact-info-section {
      align-items: flex-start;
    }
    .products-text{
      align-items: flex-start;
    }
    .contact-us-details {
      padding: 0;
    }
    .footer-logo-icon1 {
      height: 93px;
      width: 157px;
    }
    .social-media-icons1 {
      display: none;
    }
    .reach-us-section{
      align-items: flex-start;
    }
    .copyright-div {
      align-items: flex-start;
      flex-direction: column;
      padding: 0 20px;
      gap: 40px;
      width: 90vw;
    }
    .copyright-2018-24 {
      order: 7;
      width: 90vw;
      padding: 0;
      text-align: left;
    }
    .straight-line {
      display: none;
    }
    .privacy-div {
      width: auto;
      order: 6;
      flex-direction: column;
      text-align: left;
      gap: 15px;
    }
    .social-media-icons {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
    }
    .social-media-icons1-mobile {
      display: flex;
      /* order: 5; */
    }
    .followus-mobile {
      order: 5;
      display: flex;
      gap: 15px;
      flex-direction: column;
    }
    .products6,
    .reach-us,
    .services3,
    .followus-text {
      font-size: 18px;
    }
    .logo-with-text {
      align-items: flex-start;
    }
    .footer1 {
      align-items: flex-start;
    }
    .LineDiv1{
      display: none;
    }
    .LineDiv {
      display: block;
      width: 90vw;
      position: relative;
      border-top: 1px solid #dfdfdf;
      box-sizing: border-box;
      height: 2px;
      margin-top: 15px;
    }  
    .line-framecopyright{
      width: 100vw;
    }
    .iso-logo-row{
      width: 100%;
    }
    .footer-logo{
      width: 75px;
      height: 75px;
    }
    .startupindia{
      top: 8px;
      height: 27px;
      width: auto;
    }
    .dropdown-services {
      z-index: 1000; 
    }
    
    .show-dropdown-services {
      z-index: 1000; 
    }
    .dropdown-products {
      z-index: 1000; 
      height: 280px;
    }
    
    .show-dropdown-products {
      z-index: 1000; 
    }  
    .iso-logo-container{
      width: 100%;
      flex-direction: column;
      box-sizing: border-box;
      padding: 0 30px;
      gap: 20px;
    }
    .vertical-line2{
      display: none;
    }
    .iso-logo-cert{
      box-sizing: border-box;
    }
    /* .aws-partner-div{
      justify-content: flex-start;
    } */
    .partners-vm-div{
      padding: 0;
      border: none;
    }
  }
  @media screen and (max-width: 391px) {
    .footer-logo {
      width: 70px;
      height: 70px;
    }
    .startupindia{
      top: 8px;
      height: 27px;
      width: auto;
    }
  }
  @media screen and (max-width: 361px) {
    .iso-logo1{
      width: 70px;
      height: 70px;
    }  
    .iso-logo-row{
      gap: 10px;
    }
  }
  @media (min-width: 420px) and (max-width: 450px){
    .dropdown-menu{
      top: 81px;
    }      
  }

  @media screen and (max-width: 321px){
    .header{
      padding-left: 30px;
      width: 100%;
      gap: 20px;
    }      
    .iso-logo1{
      width: 60px;
    }
    .startupindia{
      height: 25px;
    }
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    .newsletter-1-icon {
        margin-left: -25px;
    }
}
  /* @media (min-width: 768px) and (max-width: 1280px) {
    .iso-logo-container {
        padding: 0 100px;
        margin-left: -16%;
    }
} */



  @media (min-width: 800px) and (max-width: 1280px) {
    .iso-logo-container {
      flex-wrap: wrap; 
      justify-content: center; 
      padding: 0 20px; 
      gap: 25px; 
    }
  }
    @media (min-width: 768px) and (max-width: 1024px) {
    .newsletter-1-icon {
      width: 320px;
      margin-left: -1px; 
      object-fit: contain; 
    }
  
    .iso-logo-container {
      flex-wrap: wrap; 
      justify-content: center;
      padding: 0 15px;
      padding-left: 20%; 
      gap: 10px; 
    }
  }
  
  

  @media (min-width: 768px) and (max-width: 1280px) {
    .newsletter-1-icon {
      width: 376px; /* Maintain original width */
      height: auto; /* Maintain aspect ratio */
      max-width: 100%; /* Ensure responsiveness */
      position: relative;
      left: -20px; /* Adjust the image to the left */
      object-fit: cover;
      max-height: 100%;
      z-index: 1;
    }
  }

@media screen and (min-width: 1024px) and (max-width: 600px) {
  img {
      width: 50%;
      max-width: 250px;
      height: auto;
      display: block;
      margin: 0 auto;
      object-fit: contain;
      overflow: hidden;
      margin-left: 11px;
  }
}
/* @media screen and (min-width: 768px) and (max-width: 1194px) {
  .partners-vm {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: -60px;
    padding: 20px 30px;
  }
  .partners-vm-div{
    padding: 0;
    border: none;
  }
} */
/* @media (min-width: 1114px){
  .aws-partner-div {
      margin-left: 10px;
      transform: translateX(30px);
  }
  .partners-text {
    margin-left: 25px; 
    transform: translateX(30px);
  }
} */
@media (min-width: 1114px) and (max-width:705px){
.iso-logo-cert {
  margin-left: 80px;
  border-right:1px solid #dfdfdf;
  padding: 10px 20px;
}
}