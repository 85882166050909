@import url("https://use.typekit.net/izj8fgu.css");

body {
  margin: 0;
  line-height: normal;
  font-family: var(--font-proxima-nova);
}
:root {
  /* fonts */
  --font-proxima-nova: 'Proxima Nova', sans-serif;

  
  /* font sizes */
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-17xl: 36px;
  --font-size-3xl: 22px;
  --font-size-10xl: 29px;
  --font-size-lg: 18px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-381xl: 400px;
  --font-size-81xl: 100px;
  --font-size-141xl: 160px;
  --font-size-61xl: 80px;

  /* Colors */
  --bk6: #fff;
  --color-lightseagreen-100: #5dbeb5;
  --g5: #19ab9f;
  --b4: #4299d4;
  --b3: #1674b4;
  --color-darkcyan-100: #298a82;
  --g6: #068e83;
  --bk3: #7e7e7e;
  --color-deepskyblue: #53aae4;
  --bk5: #f3f3f3;
  --b7: #cfeeff;
  --bk2: #4e4e4e;
  --b2: #344c99;
  --b6: #96daff;
  --color-lightskyblue-100: #5cb6e7;
  --b1: #002169;
  --color-gainsboro: #d9d9d9;
  --color-darkgoldenrod: #b48100;
  --color-black: #000;
  --color-lavender: rgba(210, 237, 255, 0.95);

  /* Gaps */
  --gap-12xl: 31px;
  --gap-xl: 20px;
  --gap-7xs: 6px;
  --gap-6xs: 7px;
  --gap-0: 0px;
  --gap-3xl: 22px;
  --gap-mid: 17px;
  --gap-3xs: 10px;
  --gap-base: 16px;
  --gap-44xl: 63px;
  --gap-6xl: 25px;
  --gap-21xl: 40px;
  --gap-22xl: 41px;
  --gap-9xs: 4px;
  --gap-61xl: 80px;
  --gap-42xl: 61px;
  --gap-11xl: 30px;
  --gap-45xl: 64px;

  /* Paddings */
  --padding-26xl: 45px;
  --padding-xl: 20px;
  --padding-12xs: 1px;
  --padding-11xs: 2px;
  --padding-10xs: 3px;
  --padding-6xs: 7px;
  --padding-11xl: 30px;
  --padding-base: 16px;
  --padding-36xl: 55px;
  --padding-71xl: 90px;
  --padding-17xl: 36px;
  --padding-14xl: 33px;
  --padding-4xs: 9px;
  --padding-lg: 18px;
  --padding-12xl: 31px;
  --padding-7xs: 6px;
  --padding-9xs: 4px;
  --padding-mini: 15px;
  --padding-sm: 14px;
  --padding-132xl: 151px;
  --padding-3xs: 10px;
  --padding-22xl: 41px;
  --padding-2xs: 11px;
  --padding-5xs: 8px;
  --padding-34xl: 53px;
  --padding-lgi: 19px;
  --padding-46xl: 65px;
  --padding-13xl: 32px;
  --padding-18xl: 37px;
  --padding-6xl: 25px;
  --padding-4xl: 23px;
  --padding-3xl: 22px;
  --padding-smi: 13px;
  --padding-xs: 12px;

  /* Border radiuses */
  --br-41xl: 60px;
  --br-31xl: 50px;
  --br-21xl: 40px;
  --br-5xs: 8px;
  --br-11xl: 30px;
}
