.imaging-service-icon {
  position: absolute;
  height: 61.11%;
  width: 10.78%;
  top: 19.44%;
  right: 80.53%;
  bottom: 19.44%;
  left: 8.7%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.clinical-trials-service-icon {
  position: absolute;
  height: 61.11%;
  width: 11.96%;
  top: 19.44%;
  right: 79.89%;
  bottom: 19.44%;
  left: 8.15%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.digital-health-service-icon {
  position: absolute;
  height: 61.11%;
  width: 11.96%;
  top: 19.44%;
  right: 79.89%;
  bottom: 19.44%;
  left: 8.15%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.bio-informatics-service-icon {
  position: absolute;
  top: 7px;
  left: 15px;
  width: 22px;
  height: 22px;
}

.imaging4 {
  position: absolute;
  top: 0;
  left: calc(50% - 41px); /* Adjust this value if the text is not centered properly */
  line-height: 36px;
}

.imaging-in-service,
.digital-health-in-service,
.clinical-trials-in-service,
.bioinformatics-in-service {
  position: relative; /* Changed to relative for direct children of the dropdown */
  height: 36px; 
  width: 100%;
  transition: background-color 2s ease, border-bottom 2s ease, color 2s ease;
  display: block;
}
.dropdown-services2 {
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--color-lightcyan);
  box-sizing: border-box;
  width: 100%;
  height: 36px;
}

.dropdown-services {
  /* margin-top: 10%;
  display: none;
  left: 0; */
  width: 184px;
  /* position: absolute; */
  position: fixed;
  background-color: var(--bk6);
  box-shadow: 0 2px 2px rgba(63, 129, 173, 0.25);
  /* top: 100%; */
  margin-top: 30px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--b3);
  font-family: var(--font-proxima-nova);
  z-index: 1000;
  display: block; /* Make sure it's block or inline-block, not 'none' */
  opacity: 0; /* Start fully transparent */
  visibility: hidden; /* By default, it shouldn't be visible */
  transform: translateY(-20px); /* Start a bit higher for a slide effect */
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0s, 0s, 0.5s; /* No delay for appearing, 0.5s delay for disappearing */
  font-weight: 200;
}
.show-dropdown-services {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Now it's visible */
  transform: translateY(0); /* Align it to its natural position */
  transition-delay: 0.3s; 
}
/* Common styles for hover state */
.imaging-in-service:hover,
.digital-health-in-service:hover,
.clinical-trials-in-service:hover,
.bioinformatics-in-service:hover {
  background-color: #1674b4;
  border-bottom: 1px solid #002169;
}

/* Text color for hover state */
.imaging-in-service:hover .imaging4,
.digital-health-in-service:hover .imaging4,
.clinical-trials-in-service:hover .imaging4,
.bioinformatics-in-service:hover .imaging4 {
  color: #fff;
}
